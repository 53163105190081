footer{
    justify-content: center;
    padding: 20px 0px;
   align-items: center;
   text-align: center;
}
footer p{
    margin: 0;
    padding-top: 15px;
    font-weight: 400;
    border-top: 1px solid #E3E3E3;
}
