.eservices-helpdesk {
    padding: 20px;
 
    border-radius: 5px;
    max-width: 800px;
    margin: 20px auto;
    text-align: center;
  }
  
  .eservices-helpdesk h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .eservices-helpdesk p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
  }
  
  .service-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 30px 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-card h3 {
    font-size: 16px;
    margin: 0;
    color: #333;
    text-align: center;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .eservices-helpdesk {
      padding: 15px;
    }
  
    .service-card {
      padding: 10px;
    }
  
    .service-card h3 {
      font-size: 14px;
    }
  }
  