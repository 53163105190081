/* Container for the features section */
.content {
    padding: 20px;
    background-color: #fff;
}

/* Row styling to handle columns and spacing */
.row {
    margin: 0;
}

/* Card styling */
.features-card {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #000;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.features-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Icon container styling */
.features-card .icon {
    padding: 20px;
    text-align: center;
}

.features-card .icon img {
    max-width: 40%;
    height: auto;
}

/* Information section styling */
.features-card .info {
    padding: 15px;
}

.features-card .info .card-title {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
    color: #333;
}

.features-card .info .text {
    font-size: 1rem;
    color: #666;
}

/* Responsive Design */
@media (max-width: 767px) {
    .row-cols-md-3 {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .row-cols-md-3 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 992px) {
    .row-cols-md-3 {
        grid-template-columns: repeat(3, 1fr);
    }
}
