/* HeaderTop.css */
.header-top {
    background-color: #f8f9fa; /* Light background color */
    padding: 10px 0;
  }
  .header-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}.header-links>ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
}
  .header-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .header-links li:not(:last-child) {
    margin: 0 40px 0 0;
}
  .header-links li {
    margin-bottom: 5px;
  }
  
  .header-links a {
    color: #007bff; /* Link color */
    text-decoration: none;
  }
  
  .header-links a:hover {
    text-decoration: underline;
  }
  
  .social-links {
    display: flex;
    align-items: center;
  }
  
  .social-title {
    margin-right: 10px;
  }
  
  .social-links a {
    margin-right: 10px;
    color: #333; /* Icon color */
  }
  
  .social-links i {
    font-size: 18px;
  }
  