.services-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    display: flex;
    flex: 1;
    padding: 20px;
    padding: 20px 50px;
  }
  
  .content-left {
    flex: 8; /* Adjusted to take 8 parts of the space */
    padding-right: 20px;
  }
  
  .content-right {
    flex: 4; /* Adjusted to take 4 parts of the space */
    position: sticky;
    top: 20px; /* Adjust this value based on your layout */
  }
  
  .sticky-form {
    background-color: #0d6efd17;
    padding: 20px;
    border: 1px solid rgba(153, 153, 153, .2);
    border-radius: 5px;
    position: sticky;
    top: 120px;
  }
  
  .content-section {
    margin-bottom: 20px;
  }
  
  .section-head {
    margin-bottom: 20px;
  }
  
  .services-benefits-container {
    display: flex;
    flex-direction: column;
  }
  
  .benefit {
    margin-bottom: 20px;
  }
  
  .footer {
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
