/* Container styling */
.service-highlights {
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
    background-color: #f5f5f5;
    position: sticky; /* Ensure sticky positioning */
    top: 65px; /* Position from the top */
    z-index: 10; /* Ensure it stays above other elements */
    padding: 30px;
    border-radius: 10px;
}

.service-highlights h5 {
    font-size: 1rem;
}

.swiper-container {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.swiper-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out; /* Smooth transition effect */
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: auto;
    box-sizing: border-box;
    width: auto !important;
}

.swiper-slide-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
}

.swiper-slide.active {
    background-color: #0d6efd17;
    color: #fff;

    border-radius: 5px;
}

.service-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.add-to-cart-btn {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.service-details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.service-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    padding: 15px;
    width: 100%;
    margin: 10px 20px;
    /* max-width: 400px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-card-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 15px;
}

.service-card-content {
    flex: 1;
}

.add-to-cart-btn {
    background: #007bff; /* Button background color */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.add-to-cart-btn:hover {
    background: #0056b3; /* Button hover background color */
}   