

/* src/components/common/ContactForm.css */

.contact-form-container {
    position: relative;

}

.contact-form {
    transition: height 0.3s ease;
    /* Smooth transition for height change */
}

.manufacturer-list {
    /* position: absolute; */
    
    top: 0;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 10;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    
    top: 100%;
    /* Position below the input field */
    left: 0;
    right: 0;
    max-height: 400px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-wrap: wrap;


}
/* Hide scrollbar in Webkit browsers */
.manufacturer-list::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}
.manufacturer-list h3 {
    margin: 0 0 16px;
}

.manufacturer-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.manufacturer-list ul li {
    margin-bottom: 5px;
}

.manufacturer-list ul li button {
    background: transparent;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    width: 100%;
}

.manufacturer-list .close-list {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #007bff;
}

.manufacturer-list h3 {
    font-size: 24px;
}

.manufacturer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns */
    gap: 10px;
}

.manufacturer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.manufacturer-list .manufacturer-item {
    width: 50%;
    /* Two columns layout */
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}

.manufacturer-list .manufacturer-item img {
    max-width: 100px;
    /* Adjust size as needed */
    margin-bottom: 5px;
    height: auto;
}

.manufacturer-item span {
    font-size: 14px;
    color: #333;
}

.manufacturer-list .manufacturer-item span {
    display: block;
    margin-top: 5px;
}