.service-price-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .service-price-table th, .service-price-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .service-price-table th {
    background-color: #f2f2f2;
  }
  
  .service-price-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .service-price-table tr:hover {
    background-color: #f1f1f1;
  }
  