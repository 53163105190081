/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    align-items: center;
    padding: 10px 60px;
    background-color: #fff;
    /* Adjust as needed */
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.06);
}

.header-logo .logo {
    height: 40px;
    /* Adjust logo size as needed */
}

.header-right {
    display: flex;
    align-items: center;
}

.login-button {
    background-color: #007bff;
    /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
}

.login-button:hover {
    background-color: #0056b3;
    /* Darker shade on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-logo {
        margin-bottom: 10px;
    }

    .header-right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}
.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
.whatsapp-button {
    position: relative;
    background-color: #25D366; /* WhatsApp green */
    color: #fff; /* White text */
    border: none;
    border-radius: 8px; /* Rounded corners */
    padding: 10px 20px; /* Padding */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .whatsapp-button:hover {
    background-color: #1EBE5F; /* Darker green on hover */
  }
  
  .whatsapp-button:focus {
    outline: none; /* Remove default focus outline */
  }
  
  .whatsapp-icon {
    position: absolute;
    top: 0px;
    right: -10px;
    width: 24px;
    height: 24px;
  }
  
  .whatsapp-icon img {
    width: 100%;
    height: auto;
  }