.faq-content-wrap2 .contact-form-wrap .title-area {

    background: #243c8e;
    padding: 10px 25px;
    margin-bottom: 0;
}

.title-area {
    margin-bottom: calc(var(--section-title-space) - 17px);
    position: relative;
    z-index: 2;
}

.faq-area-2 {
    padding: 130px 0px;

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}



.faq-content-wrap2 .contact-form-wrap {
    position: fixed;
    top: 70px;
    /* Adjust this value as needed */
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    right: 50px;
    bottom: 0;
    padding: 0;
    max-width: 545px;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;

}


.form-group {
    margin-bottom: 20px;
    position: relative;
}

select,
.single-select,
.form-control,
.form-select,
textarea,
input {
    height: 40px;
    padding: 0 20px;

    color: var(--body-color);
    background-color: #f8f9fa;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    font-family: var(--body-font);
    transition: 0.4s ease-in-out;
    padding-right: 45px;
    border-radius: 5px;
}

select.style3,
.single-select.style3,
.form-control.style3,
.form-select.style3,
textarea.style3,
input.style3 {
    background: #f8f9fa;
}

.faq-content-wrap2 .contact-form-wrap .contact-form {
    padding: 25px 40px;
}

.btn {
    position: relative;
    z-index: 2;
    vertical-align: middle;

    border: none;
    align-items: center;
    text-align: center;
    background-color: #243c8e;
    color: #fff;
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
    font-weight: 600;
    line-height: 25px;
    padding: 15px 32px;
    overflow: hidden;
    transition: all 0.35s;
    border-radius: 5px;
}

.btn:focus,
.btn:hover,
.btn.active {
    /* color: var(--theme-color); */
    background-color: #243c8e;
    outline: none;
    box-shadow: none;
}

.sticky-style {
    position: sticky;
    top: 0;
    left: 50%;
    margin-top: 0;
    z-index: 1000;
    background: white;
    box-shadow: 0px 8px 30px rgba(247, 186, 235, 0.1);
    border-top: 1px;
}

.box-1 {
    background: #F4F8FF;

}

.box-2 {
    background: #FCF5FE;

}

.box-3 {
    background: #F6FFF5;

}

.box-4 {
    background: #FFFFE9;

}

/* PremiumMember.css */
.premium-container {
    width: 100%;

    position: relative;
    padding-left: 4px;
}

.step {
    display: flex;
    align-items: center;
    position: relative;

}

.step::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #ccc;
    z-index: -1;
}

.number {
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    width: 46px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    margin-right: 20px;
    z-index: 1;
}

.content {

    padding: 20px;
    text-align: left;
    margin-top: 30px;
    
    width: 100%;
}

h2 {
    margin-top: 0;
}

.eligibility-btn {
    display: block;

    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
}

.eligibility-btn:hover {
    background-color: #0056b3;
}

.intro-text {
    text-align: left;
    font-size: 1.2em;
    color: #555;
    margin-bottom: 30px;
}



.benefits-list {
    list-style-type: none;
    padding: 0;
}

.benefits-list li {
    font-size: 1.1em;
    color: #333;
    margin-bottom: 15px;
    text-align: left;
    align-items: center;
}





.closing-text {
    text-align: left;
    font-size: 1.1em;
    font-weight: 600;
    color: #555;
    margin-top: 20px;
    margin-bottom:  20px;
} 
 
.join-btn {
    display: block;
    width: fit-content;

    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.join-btn:hover {
    background-color: #0056b3;
}
.content h3{

    font-size: 1.2rem;

}